

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap');


.align-justify{
  text-align: justify;
}

.cusorOver,
.hover-opacity {
  cursor: pointer;
}

.cusorOver:hover {
  background: #f1f1f1;
}

.hover-opacity:hover {
  opacity: 0.6;
}

.italic{
  font-style: italic;
  text-align: center;
}

.fs1em{  font-size: 1em; }
.fs115em{  font-size: 1.15em; }
.fs85em{  font-size: 0.85em; }
.fs125em{  font-size: 1.25em; }
.fs07em{  font-size: 0.7em; }


.blogContent  img{
  max-width: 100%;
}



.fade-in {
  -webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.slide-right {
  -webkit-animation: slide-right .5s cubic-bezier(.25, .46, .45, .94) both;
  animation: slide-right .5s cubic-bezier(.25, .46, .45, .94) both
}
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px)
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px)
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

/* Responsive YouTube Video Styles */
.ql-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;  /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}

.ql-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}